@mixin card-theme($theme) {
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  .custom-card {
    border: 0 solid transparent;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;

    &.smaller-header {
      .mat-mdc-card-header {
        padding: 10px 16px 0;
      }
    }

    &.full-width-header {
      .mat-mdc-card-header {
        width: calc(100% - 2rem);
        .mat-mdc-card-header-text {
          width: 100%;
        }
      }
    }

    .mat-mdc-card-title {
      font-size: 16px;
      text-transform: uppercase;
    }

    .mat-mdc-card-subtitle {
      font-size: 14px;
    }

    .mat-mdc-card-content {
      font-size: 14px;
    }

    &.bordered {
      border: 1px solid map-get($foreground, dividers) !important;
    }
  }

  .summary-card {
    height: 133px;
    width: 20%;

    .summary-card-header {
      overflow: hidden;
      height: 26px;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .summary-card-title {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .summary-card-content {
      height: 100px;
      overflow: hidden;
    }

    .progress-bar {
      padding-bottom: 8px;

      .progress-percent {
        font-size: 30px;
      }
    }

    .award-icon {
      font-size: 60px;
      width: 60px;
      height: 60px;
    }
  }

  .chart-card {
    height: 528px;
    min-height: 528px;

    .card-header {
      padding: 10px 16px 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  }
}


