@use "@angular/material" as mat;

/* --- palettes --- */
$eeule-palette: (
  50: #edf1f6,
  100: #ced4dc,
  200: #a3abbd,
  300: #8d97ac,
  400: #6c757c,
  500: #4b5564,
  600: #3b4453,
  700: #353b47,
  800: #313742,
  900: #212629,
  A50: #f9fafd,
  A75: #ced4dc44,
  A85: #ced4dc77,
  A100: #f7f8fb,
  A200: #3b445377, //383f4d
  A300: #3f4857,
  A350: #4e546766,
  A400: #4e5467,
  A500: #4b5564cc,
  A700: #272c37
);

$primary-default: mat.define-palette(mat.$light-green-palette, 800, 600);
$accent-default: mat.define-palette(mat.$teal-palette);
$primary-dark: mat.define-palette(mat.$light-green-palette, A700, A700);
$accent-dark: mat.define-palette(mat.$cyan-palette, 500);
//$accent-dark: mat.define-palette(mat.$pink-palette, 500);
//$accent-dark: mat.define-palette(mat.$deep-orange-palette, 400);

/* --- colors --- */
$default-background: (
  status-bar: map-get($eeule-palette, 100),
  app-bar: white,
  background: map-get($eeule-palette, 50),
  secondary-background: map-get($eeule-palette, A50),
  tertiary-background: map-get($eeule-palette, 50),
  quaternary-background: map-get($eeule-palette, A100),
  differ-table-row: #31374205,
  table-row-hover: #558b2f11,
  hover: rgba(0, 0, 0, 0.04),
  card: white,
  dialog: white,
  tooltip: map-get($eeule-palette, 800)
);

$default-foreground: (
  base: map-get($eeule-palette, 900),
  divider: map-get($eeule-palette, 100),
  dividers: map-get($eeule-palette, A75),
  disabled: map-get($eeule-palette, 300),
  disabled-button: map-get($eeule-palette, 200),
  disabled-text: map-get($eeule-palette, 200),
  disabled-form-field: map-get($eeule-palette, A200),
  hint-text: map-get($eeule-palette, 300),
  secondary-text: map-get($eeule-palette, 400),
  text: map-get($eeule-palette, 900),
  icon: map-get($eeule-palette, 700),
  icons: map-get($eeule-palette, 800),
  elevation: map-get($eeule-palette, 500),
);

$dark-background: (
  status-bar: map-get($eeule-palette, 800),
  app-bar: map-get($eeule-palette, 500),
  background: map-get($eeule-palette, 600),
  secondary-background: map-get($eeule-palette, A200),
  tertiary-background: map-get($eeule-palette, 600),
  quaternary-background: map-get($eeule-palette, A300),
  differ-table-row: #edf1f605,
  table-row-hover: #64dd1733,
  hover: rgba(255, 255, 255, 0.04),
  card: map-get($eeule-palette, 700),
  dialog: map-get($eeule-palette, 700),
  tooltip: map-get($eeule-palette, 800),
  //disabled-button: lightgrey,
  //raised-button: lightgrey,
  //focused-button: lightgrey,
  //selected-button: lightgrey,
  //selected-disabled-button: lightgrey,
  //disabled-button-toggle: lightgrey,
);

$dark-foreground: (
  base: map-get($eeule-palette, 100),
  divider: map-get($eeule-palette, A400),
  dividers: map-get($eeule-palette, A350),
  disabled: map-get($eeule-palette, 300),
  disabled-button: map-get($eeule-palette, 200),
  disabled-text: map-get($eeule-palette, 300),
  disabled-form-field: map-get($eeule-palette, 400),
  hint-text: map-get($eeule-palette, 300),
  secondary-text: map-get($eeule-palette, 200),
  icon: map-get($eeule-palette, 200),
  icons: map-get($eeule-palette, 200),
  text: map-get($eeule-palette, 100),
  //elevation: map-get($eeule-palette, 500),
  //slider-min: map-get($eeule-palette, 100),
  //slider-off: map-get($eeule-palette, 800),
  //slider-off-active: map-get($eeule-palette, 600),
);

$highlights: (
  primary: map-get($primary-default, default),
  primary-dark: map-get($primary-dark, default),
  secondary: map-get($primary-default, 600),
  accent-1: map-get($accent-default, default),
  accent-1-dark: map-get($accent-dark, default),
  accent-2: mat.get-color-from-palette(mat.$deep-orange-palette, A200),
  accent-3: mat.get-color-from-palette(mat.$pink-palette, 500),
  accent-4: mat.get-color-from-palette(mat.$cyan-palette, A700),
  accent-5: mat.get-color-from-palette(mat.$cyan-palette, 800),
  success: mat.get-color-from-palette(mat.$green-palette, 500),
  info: mat.get-color-from-palette(mat.$amber-palette, 500),
  warn: mat.get-color-from-palette(mat.$deep-orange-palette, 500),
  error: mat.get-color-from-palette(mat.$red-palette, A700),
  bronze: #cd7f32,
  silver: silver,
  gold: gold,
  platinum: mat.get-color-from-palette(mat.$blue-grey-palette, A200),
  inactive: mat.get-color-from-palette(mat.$blue-grey-palette, 400),
  optional: mat.get-color-from-palette(mat.$blue-palette, A100),
);
