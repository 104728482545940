@use "@angular/material" as mat;

@mixin highlights($theme) {
  .primary {
    color: mat.get-theme-color($theme, primary, default);
  }

  .primary-background-color {
    background-color: mat.get-theme-color($theme, primary, default);
  }

  .accent {
    color: mat.get-theme-color($theme, accent, default);
  }

  .accent-background-color {
    background-color: mat.get-theme-color($theme, accent, default);
  }

  .secondary {
    color: mat.get-theme-color($theme, primary, lighter)
  }

  .primary-default {
    color: map-get($highlights, primary);
  }

  .primary-dark {
    color: map-get($highlights, primary-dark);
  }

  .accent-default {
    color: map-get($highlights, accent-1);
  }

  .accent-dark {
    color: map-get($highlights, accent-1-dark);
  }

  .secondary-hl {
    color: map-get($highlights, secondary)
  }

  .secondary-background-color {
    background-color: map-get($highlights, secondary)
  }

  .accent-2 {
    color: map-get($highlights, accent-2);
  }

  .accent-2-background-color {
    background-color: map-get($highlights, accent-2);
  }

  .accent-3 {
    color: map-get($highlights, accent-3);
  }

  .accent-3-background-color {
    background-color: map-get($highlights, accent-3);
  }

  .accent-4 {
    color: map-get($highlights, accent-4);
  }

  .accent-4-background-color {
    background-color: map-get($highlights, accent-4);
  }

  .success {
    color: map-get($highlights, success);
  }

  .success-background-color {
    background-color: map-get($highlights, success);
  }

  .info {
    color: map-get($highlights, info);
  }

  .info-background-color {
    background-color: map-get($highlights, info);
  }

  .warn {
    color: map-get($highlights, warn);
  }

  .warn-background-color {
    background-color: map-get($highlights, warn);
  }

  .error {
    color: map-get($highlights, error);
  }

  .error-background-color {
    background-color: map-get($highlights, error);
  }

  .gold {
    color: map-get($highlights, gold);
  }

  .gold-background-color {
    background-color: map-get($highlights, gold);
  }

  .silver {
    color: map-get($highlights, silver);
  }

  .silver-background-color {
    background-color: map-get($highlights, silver);
  }

  .bronze {
    color: map-get($highlights, bronze);
  }

  .bronze-background-color {
    background-color: map-get($highlights, bronze);
  }

  .platinum {
    color: map-get($highlights, platinum);
  }

  .inactive {
    color: map-get($highlights, inactive);
  }

  .optional {
    color: map-get($highlights, optional);
  }

  .platinum-background-color {
    background-color: map-get($highlights, platinum);
  }

  &.white-text {
    color: white;
  }

  &.light-text {
    color: map-get($eeule-palette, 50);
  }

  &.dark-text {
    color: map-get($eeule-palette, 900);
  }
}
