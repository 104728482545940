@mixin differ-rows($background) {
  .differ-rows {
    tr:nth-child(even) {
      background: map-get($background, differ-table-row);
    }
  }
}

@mixin row-hover($background) {
  .row-hover {
    tr:has(td):hover {
      background: map-get($background, table-row-hover) !important;
    }
    &.pointer {
      td {
        cursor: pointer;
      }
    }
  }
}

@mixin custom-table($theme) {
  //$type: map-get($theme, type);
  $color: map-get($theme, color);
  $background: map-get($color, background);
  @include differ-rows($background);
  @include row-hover($background);
  .differ-rows {
    tr:nth-child(even) {
      background: map-get($background, differ-table-row);
    }

    //@if ($type == default) {
    //  tr:nth-child(even) {
    //    background: map-get($background, differ-table-row);
    //    //background: rgba(126, 126, 126, 0.04);
    //  }
    //} @else {
    //  tr:nth-child(even) {
    //    background: map-get($background, differ-table-row);
    //    //background: rgba(126, 126, 126, 0.1);
    //  }
    //}
  }
  .row-hover {
    tr:hover {

    }
  }
}
