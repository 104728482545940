@use "@angular/material" as mat;

@mixin project-info-tabs() {
  .project-info-page .mat-mdc-tab-body-content {
    max-height: calc(100vh - 316px);
  }
}

@mixin tabs-theme($theme) {
  @include project-info-tabs();

  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  $type: map-get($theme, type);
  .mat-mdc-tab-label-container {
    border-bottom: 1px solid;
    border-bottom-color: map-get($foreground, divider);
  }

  .default {
    .mat-mdc-tab-body-wrapper {
      height: 100%;
    }

    .mat-tab-label.mat-tab-label-active {
      background: transparent !important;
    }

    .mat-mdc-tab {
      &.mdc-tab--active {
        background-color: transparent !important;

        .mdc-tab__text-label {
          color: map-get($foreground, text);
        }
      }
    }
  }
}
