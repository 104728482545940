@mixin mat-nav-list($theme) {
  $type: map-get($theme, type);
  .mdc-list-item {
    transition: background-color ease-in-out 50ms;

    &:focus::before {
      background-color: unset !important;
    }

    @if ($type == dark) {
      &.is-active {
        //background-color: map-get($eeule-palette, 500);
        background-color: map-get($eeule-palette, A500);
        //background-color: map-get($eeule-palette, A300);

        .nav-label {
          color: map-get($eeule-palette, 50)
        }
      }
    }
    @else {
      &.is-active {
        background-color: map-get($eeule-palette, 600);

        .nav-label {
          color: map-get($eeule-palette, 50)
        }
      }
    }
  }
}

@mixin context-navigation($theme) {
  $type: map-get($theme, type);
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  $background: map-get($color, background);

  .context-nav {
    background-color: if($type == dark, map-get($background, card), map-get($background, app-bar));
    @include mat-nav-list($theme);
  }
}
