@mixin scrollbar($theme) {
  $type: map-get($theme, type);
  .custom-scrollbar, [cdkscrollable] {
    overflow-y: auto;
    //@include scrollbar-appearance-alt($type);
    @include scrollbar-appearance($type);
  }
}

@mixin scrollbar-appearance($type) {
  &.sb-spacing {
    padding-right: 1px;
  }

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: if($type == dark, rgba(255, 255, 255, 0.02), rgba(0, 0, 0, 0.02));
    //background: if($type == dark, #4b4b5c, map-get($eeule-palette, A50));
    //border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: if($type == dark, map-get($eeule-palette, A400), #7c869b);
    //border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: if($type == dark, #5f6578, #6b758a);
  }
}

@mixin scrollbar-appearance-alt($type) {
  &.sb-spacing {
    padding-right: 1px;
  }

  &::-webkit-scrollbar {
    height: 12px;
    width:12px;
  }

  &::-webkit-scrollbar-track {
    background: if($type == dark, map-get($eeule-palette, 800), rgba(0, 0, 0, 0.175));
  }

  &::-webkit-scrollbar-thumb {
    background: if($type == dark, #8d97acaa, #7c869b);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: if($type == dark, #8d97acee, #6b758a);
  }
}
