@use "@angular/material" as mat;
@import "scrollbar-theme";

@mixin base() {
  .ql-label {
    font-size: .9em;
  }

  .ql-toolbar, .ql-container {
    font-family: 'Roboto', sans-serif;
  }

  .ql-tooltip {
    left: 8px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0 1px 7px -3px !important;
  }

  .small-radius {
    .ql-toolbar {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:not(.flat-bottom) .ql-container {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .h-auto .ql-container {
    height: auto;
  }

  .ql-custom-height .ql-container {
    height: calc(100% - 42px);
  }

  .ql-minh-50 .ql-editor {
    min-height: 50px;
  }

  .ql-minh-100 .ql-editor {
    min-height: 100px;
  }

  .ql-minh-120 .ql-editor {
    min-height: 120px;
  }

  .ql-minh-150 .ql-editor {
    min-height: 150px;
  }

  .ql-minh-200 .ql-editor {
    min-height: 200px;
  }

  .ql-maxh-100 .ql-editor {
    max-height: 100px;
  }

  .ql-maxh-120 .ql-editor {
    max-height: 120px;
  }

  .ql-maxh-150 .ql-editor {
    max-height: 150px;
  }

  .ql-maxh-200 .ql-editor {
    max-height: 200px;
  }

  .ql-maxh-250 .ql-editor {
    max-height: 250px;
  }

  .ql-maxh-300 .ql-editor {
    max-height: 300px;
  }

  .ql-maxh-400 .ql-editor {
    max-height: 400px;
  }
}

@mixin border-colors($foreground) {
  .ql-picker.ql-expanded .ql-picker-label,
  .ql-picker-options,
  .ql-tooltip,
  .ql-toolbar,
  .ql-container {
    border-color: var(--mdc-outlined-text-field-outline-color) !important;
  }

  .default-border-color {
    .ql-picker.ql-expanded .ql-picker-label,
    .ql-picker-options,
    .ql-tooltip,
    .ql-toolbar,
    .ql-container {
      border-color: map-get($foreground, divider) !important;
    }
  }
}

@mixin toolbar-colors($theme, $type, $foreground, $background) {
  .ql-toolbar {
    button {
      &:hover, &:focus {
        color: mat.get-theme-color($theme, primary);

        .ql-stroke {
          stroke: mat.get-theme-color($theme, primary);;
        }
      }
    }

    .ql-picker-options {
      background-color: map-get($background, card);

      .ql-picker-item {
        &:hover {
          color: mat.get-theme-color($theme, primary);
        }

        &.ql-selected {
          color: mat.get-theme-color($theme, primary);
        }
      }
    }

    .ql-active {
      color: mat.get-theme-color($theme, primary) ! important;

      .ql-stroke {
        stroke: mat.get-theme-color($theme, primary) ! important;
      }

      .ql-fill {
        fill: mat.get-theme-color($theme, primary) ! important;
      }
    }

    button:hover .ql-fill {
      fill: mat.get-theme-color($theme, primary) !important;
    }

    .ql-picker-label {
      &:hover {
        color: mat.get-theme-color($theme, primary) !important;

        .ql-stroke {
          stroke: mat.get-theme-color($theme, primary) !important;
        }

        .ql-fill {
          fill: mat.get-theme-color($theme, primary) !important;
        }
      }
    }

    .filled-text-field-background {
      .ql-picker-options {
        background-color: var(--mdc-filled-text-field-container-color);
      }
    }

    @if ($type == dark) {
      .ql-picker-item {
        color: map-get($foreground, text);
      }

      .ql-stroke {
        stroke: map-get($foreground, text);
      }

      .ql-fill {
        fill: map-get($foreground, text);
      }

      .ql-formats {
        > span {
          color: map-get($foreground, text) !important;
        }
      }
    }
  }
}

@mixin editor-colors($theme, $type, $foreground) {
  .filled-text-field-background {
    .ql-container {
      background-color: var(--mdc-filled-text-field-container-color);
    }
  }

  .ql-container {
    color: map-get($foreground, text);
  }

  .ql-editor {
    color: map-get($foreground, text);
  }

  @if ($type == dark) {
    .ql-editor.ql-blank::before {
      color: map-get($foreground, hint-text);
    }
  }
}

@mixin tooltip-colors($foreground, $background) {
  .ql-tooltip {
    background-color: map-get($background, card) !important;
    color: map-get($foreground, text) !important;
  }

  .filled-text-field-background {
    .ql-tooltip {
      background-color: var(--mdc-filled-text-field-container-color) !important;
    }
  }
}

@mixin colors($theme) {
  $type: map-get($theme, type);
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  $background: map-get($color, background);

  @include toolbar-colors($theme, $type, $foreground, $background);
  @include editor-colors($theme, $type, $foreground);
  @include tooltip-colors($foreground, $background);
  @include border-colors($foreground);

  [disabled="disabled"] {
    .ql-toolbar, .ql-container {
      background-color: var(--mdc-filled-text-field-disabled-container-color);
    }

    .ql-container, .ql-editor, .ql-tooltip {
      color: map-get($foreground, disabled-text);
    }

    .ql-formats {
      > span {
        color: map-get($foreground, disabled-text) !important;
      }
    }

    .ql-stroke {
      stroke: map-get($foreground, disabled-text) !important;
    }

    .ql-fill {
      fill: map-get($foreground, disabled-text) !important;
    }

    .ql-picker-label:hover {
      color: map-get($foreground, disabled-text) !important;

      .ql-stroke {
        stroke: map-get($foreground, disabled-text) !important;
      }

      .ql-fill {
        fill: map-get($foreground, disabled-text) !important;
      }
    }

    button:hover .ql-fill {
      fill: map-get($foreground, disabled-text) !important;
    }
  }
}

@mixin quill($theme) {
  $type: map-get($theme, type);
  @include base();
  @include colors($theme);

  .ql-editor {
    @include scrollbar-appearance($type);
  }
}
